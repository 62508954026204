<template>
    <nav class="navbar navbar-expand-lg navbar-custom navbar-light sticky-light" id="navbar-sticky" style="background-color: red; color:white; height:77px;">
        <div class="container">
            <!-- LOGO -->
            <router-link to="/" class="logo text-uppercase">
                <img src="images/logo.png" alt="" class="logo-dark" style="width:100; height:100;"/>
                <img src="images/logo.png" alt="" class="logo-light"  style="width:100px; height:100px;"/>
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <i class="mdi mdi-menu"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link">Home </router-link>
                    </li>
                    <li class="nav-item">
                        <!-- <a href="#features" class="nav-link">Features</a> -->
                        <router-link to="/about" class="nav-link">About </router-link>
                    </li>
                    <li class="nav-item">
                        <a href="#screenshot" class="nav-link">How it Work?</a>
                    </li>
                    <li class="nav-item">
                        <a href="#testimonial" class="nav-link">Testimonial</a>
                    </li>
                    <li class="nav-item">
                        <a href="#pricing" class="nav-link">Pricing</a>
                    </li>
                    <li class="nav-item">
                        <a href="#contact" class="nav-link">Contact Us</a>
                    </li>
                </ul>
                <ul class="navbar-nav navbar-center">
                    <li class="nav-item">
                        <a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#loginModal">Login</a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="btn btn-sm nav-btn" data-bs-toggle="modal" data-bs-target="#signupModal">Sign
                            Up</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

</template>
<script>
export default {
    name: 'Nav_Sec',
}
</script>

<style scoped>
@media (max-width: 767px) { /* Mobile and tablet screens */

.logo-dark{
    width:80px;
    height:80px;
}
.navbar-collapse{
    background-color: red;
    color: white;
    text-align: center;
}
.btn{
    color:White;
}
.nav-item{
    color: white;
}
}
</style>