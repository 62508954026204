import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CheckOut from '@/views/CheckOut.vue'
import vintext from '@/views/VINText.vue'
import VehicleDetails from '@/views/VehicleDetails.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),

    props: route => ({ vehicleData: route.params.vehicleData })

  },
  {
    path: '/check_out',
    name: 'checkout',
    component: CheckOut
  },
  {
    path: '/Vin_Search',
    name: 'vintext',
    component: vintext
  },
  {
    path: '/vehicle-details',
    name: 'VehicleDetails',
    component: VehicleDetails
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
