<template>
           <!-- footer & cta start -->
           <section class="footer bg-dark">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 text-center text-lg-start">
                        <div class="footer-logo mb-4">
                            <a href="#">
                                <img src="images/logo.png" style="width:130px; height:100px;" alt="" />
                            </a>
                        </div>
                        <a href="#" class="text-white">Hello@gmail.com</a>
                        <p class="text-white">+01 ( 1234 567 890 )</p>

                        <h5 class="fs-18 mb-3 text-white">Follow Us</h5>
                        <ul class="list-inline mt-5">
                            <li class="list-inline-item">
                                <a href="javascript:void(0);" class="footer-social-icon"><i class="mdi mdi-facebook"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a href="javascript:void(0);" class="footer-social-icon"><i class="mdi mdi-twitter"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a href="javascript:void(0);" class="footer-social-icon"><i class="mdi mdi-linkedin"></i></a>
                            </li>
                            <li class="list-inline-item">
                                <a href="javascript:void(0);" class="footer-social-icon"><i class="mdi mdi-skype"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-9">
                        <div class="row">
                            <div class="col-sm-6 col-md-3">
                                <h5 class="fs-22 mb-3 fw-semibold text-white">About Us</h5>
                                <ul class="list-unstyled footer-nav">
                                    <li><a href="javascript:void(0);" class="footer-link">Support Center</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Customer Support</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">About Us</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Copyright</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <h5 class="fs-22 mb-3 fw-semibold text-white">Our Information</h5>
                                <ul class="list-unstyled footer-nav">
                                    <li><a href="javascript:void(0);" class="footer-link">Return Policy</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Privacy Policy</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Terms & Conditions</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Site Map</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <h5 class="fs-22 mb-3 fw-semibold text-white">My Account</h5>
                                <ul class="list-unstyled footer-nav">
                                    <li><a href="javascript:void(0);" class="footer-link">Press Inquiries</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Social Media Directories</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Images & B-roll</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Permissions</a></li>
                                </ul>
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <h5 class="fs-22 mb-3 fw-semibold text-white">Policy</h5>
                                <ul class="list-unstyled footer-nav">
                                    <li><a href="javascript:void(0);" class="footer-link">Vehicle History</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Vehicle Reports</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Vehicle Stolen History</a></li>
                                    <li><a href="javascript:void(0);" class="footer-link">Risponsible Supply Chain</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="footer-tagline">
            <div class="container">
                <div class="row justify-content-between py-2">
                    <div class="col-md-6">
                        <p class="text-white opacity-75 mb-0 fs-14">
                            <!-- <script>document.write(new Date().getFullYear())</script> -->
                             © SigletonNet - <a href="#" class="text-white">SigletonNet.com</a></p>
                    </div>
                    <div class="col-md-6 text-md-end">
                        <a href="javascript:void(0);" class="text-white opacity-75 fs-14">Terms Conditions & Policy</a>
                    </div>
                </div>
            </div>
        </div>
  </template>
<script>
export default {
  name: 'Footer_Comp',
}
</script>