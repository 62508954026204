<template>
  <Nav_Comp/>


 <!-- <Home_Feature/> -->
</template>

<script>
// @ is an alias to /src
// import Home_Feature from '@/components/Home_Feature.vue'
import Nav_Comp from '@/components/NavBar.vue';


export default {
  name: 'HomeView',
  components: {
    Nav_Comp,
    // Home_Feature
  },
}
</script>
