<template>
    <Nav_Sec />
    <!-- contact start -->
    <div class="space"></div>
    <section class="section bg-light" id="contact">
        <div class="container">
            <div class="row justify-content-center top_message">
                <div class="col-md-8 col-lg-6 col-sm-12 text-center">
                    <h6 class="subtitle">Check <span class="fw-bold"> Out</span></h6>
                    <h2 class="title">Pay Instantly & Get Access</h2>
                    <p class="text-muted">Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium
                        doloremque rem aperiam.</p>
                </div>
                <center>                <hr style="width:50%; color:green">
                </center>
            </div>
            <div class="row align-items-center">
                <div :class="vehicleData ? 'col-lg-6 col-sm-12' : 'col-lg-12 col-sm-12'">
                    <div class="card contact-form rounded-lg">
                        <div v-if="vehicleData" class="p-3"
                            :style="{ backgroundColor: vehicleData ? 'green' : 'red', color: 'white' }">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="images/check-hand-complete-success.svg" alt="..." height="80"
                                        style="margin-top: -5px; margin-left: 80px;" />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span>
                                        <h2 class="mb-0">Success Data Found</h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-else class="p-3" :style="{ backgroundColor: 'red', color: 'white' }">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="images/fail.svg" alt="..." height="80"
                                        style="margin-top: -5px; margin-left: 80px;" />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span>
                                        <h2 class="mb-0">Error !Data Not Found</h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-5">

                            <div class="d-flex mb-5">

                                <div class="flex-grow-1 ms-3">
                                    <div v-if="vehicleData">
                                        <h2 class="mb-0" style="color:green;">Success! </h2>
                                        <span>
                                            <h4 class="mb-0">We Have VINData Records for Your VIN</h4>
                                        </span>
                                    </div>
                                    <div v-else style="margin-top:-90px;">
                                        <h2 class="mb-0" style="color:red;">Error! </h2>
                                        <span>
                                            <h4 class="mb-0">Sorry No VINData Records for Your VIN</h4>
                                        </span>
                                        <a href="/" class="btn btn-danger">Search Again</a>
                                    </div>

                                </div>
                            </div>

                            <div class="mb-4">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-shrink-0">
                                        <div class="contact-icon bg-soft-primary text-primary">
                                            <i class="mdi mdi-car"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h5 class="mb-0 fs-18">{{ vehicleData.summary.year }} {{
                                            vehicleData.summary.make }} {{
                                                vehicleData.summary.model }}</h5>
                                    </div>
                                </div>
                                <p class="mb-1"><i class="mdi mdi-arrow-right-thin text-muted me-1"></i><a href="#"
                                        class="text-primary">VIN : {{ vehicleData.titleInformation[0].vin }}</a></p>
                            </div>
                            <div class="mb-4">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-shrink-0">
                                        <div class="contact-icon bg-soft-primary text-primary">
                                            <i class="mdi mdi-barcode"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <img src="images/333.png" alt="" srcset="">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-shrink-0">
                                        <div class="contact-icon bg-soft-primary text-primary">
                                            <i class="mdi mdi-star"></i>
                                        </div>
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <h2></h2>
                                        <h5 class="mb-0 fs-18">What People Say</h5>

                                    </div>
                                </div>
                            </div>
                            <div class="card"
                                style="background-color: #F5F5F5; height:100%; width: 100%; padding-top: 30px; padding-bottom: 30px;">
                                <div class="flex-grow-1 ms-3">
                                    <h5 class="mb-0 fs-18">"Before finding VINData I almost bought a car that was
                                        salvaged. I’m
                                        sure glad I found out the real history before making a bad decision"
                                    </h5>
                                    <center>
                                        <h5 class="fs-16 mb-2 text-primary">- Todd R.
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                            <i class="mdi mdi-star me-1" style="color:#FDB812"></i>
                                        </h5>
                                    </center>
                                </div>
                                <hr>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div class="card contact-form rounded-lg">
                        <div class="p-3" style="background-color: #1F92C2; color:white; ">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 ">
                                    <img src="images/iconizer-Cashier Machine-07.svg" alt="..." height="80"
                                        style="margin-top: -5px; margin-left: 80px;" />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <span>
                                        <h2 class="mb-0">Secure checkout</h2>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="card-body p-5">
                            <form @submit.prevent="handleSubmit">
                                <div v-if="formErrors.length" class="alert alert-danger">
                                    <ul>
                                        <li v-for="error in formErrors" :key="error">{{ error }}</li>
                                    </ul>
                                </div>

                                <div class="row">

                                    <div class="col-md-6">

                                        <div class="mb-3">
                                            <div class="card mb-3">
                                                <div class="card-body">
                                                    <div class="d-flex align-items-center">
                                                        <div
                                                            class="avatar avatar-sm rounded bg-success-subtle text-success-emphasis me-3">
                                                            <span class="mdi mdi-file-chart-outline fs-5"></span>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault1"
                                                                v-model="form.selectedOption" value="1" />
                                                            <label class="form-check-label" for="flexRadioDefault1">
                                                                1 Report <br><small> $19.99 Per Report</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <div class="card mb-3">
                                                <div class="card-body">
                                                    <div class="d-flex align-items-center">
                                                        <div
                                                            class="avatar avatar-sm rounded bg-success-subtle text-success-emphasis me-3">
                                                            <span class="mdi mdi-file-chart-outline fs-5"></span>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault2"
                                                                v-model="form.selectedOption" value="3" />
                                                            <label class="form-check-label" for="flexRadioDefault2">
                                                                3 Reports <br><small> $9.99 Per Report</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <div class="card mb-3">
                                                <div class="card-body">
                                                    <div class="d-flex align-items-center">
                                                        <div
                                                            class="avatar avatar-sm rounded bg-success-subtle text-success-emphasis me-3">
                                                            <span class="mdi mdi-file-chart-outline fs-5"></span>
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <input class="form-check-input" type="radio"
                                                                name="flexRadioDefault" id="flexRadioDefault3"
                                                                v-model="form.selectedOption" value="unlimited" />
                                                            <label class="form-check-label" for="flexRadioDefault3">
                                                                &nbsp; Unlimited <br><small> Per Report + $20.00 Per
                                                                    Month |
                                                                    Best Value</small>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label for="formLastName" class="form-label">Name</label>
                                            <input type="text" class="form-control" id="formLastName"
                                                v-model="form.name" placeholder="Full Name..." />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="formEmail" class="form-label">Email Address</label>
                                            <input type="email" class="form-control" id="formEmail" v-model="form.email"
                                                placeholder="Your email..." />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="formPhone" class="form-label">Phone Number</label>
                                            <input type="text" class="form-control" id="formPhone" v-model="form.phone"
                                                placeholder="Type phone number..." />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label for="formCardNo" class="form-label">Card No</label>
                                            <input type="tel" class="form-control" id="formCardNo" v-model="form.cardNo"
                                                inputmode="numeric" pattern="[0-9\s]{13,19}" autocomplete="cc-number"
                                                maxlength="19" placeholder="xxxx xxxx xxxx xxxx" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="formExpiration" class="form-label">Expiration</label>
                                            <input type="month" class="form-control" id="formExpiration"
                                                v-model="form.expiration" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label for="formCVV" class="form-label">CVV</label>
                                            <input type="text" class="form-control" id="formCVV" v-model="form.cvv"
                                                maxlength="3" pattern="\d{3,4}"
                                                title="Enter a 3 or 4 digit CVV number" />
                                        </div>
                                    </div>
                                </div>

                                <div v-if="isLoading" class="spinner-border text-danger m-2" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>

                                <button type="submit" class="btn btn-lg btn-block btn-gradient-success">
                                    Pay Now
                                    <i class="mdi mdi-credit-card-outline ms-1"></i>
                                </button>
                            </form>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- contact end -->
    <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue';
import Nav_Sec from '@/components/Nav_Sec.vue';

export default {
    data() {
        return {
            vehicleData: null,
            isLoading: false,
            formErrors: [],
            form: {
                name: '',
                email: '',
                phone: '',
                cardNo: '',
                expiration: '',
                cvv: ''
            }
        };
    },
    components: {
        Footer, Nav_Sec
    },
    created() {
        this.fetchVehicleData();
    },
    methods: {
        handleSubmit() {
            this.formErrors = [];

            // Validate form
            if (!this.validateForm()) {
                console.log("Form Data:", this.form);

                return;
            }

            this.processPayment();
        },
        validateForm() {
            let valid = true;

            // Clear previous errors
            this.formErrors = [];

            // Check for empty fields
            if (!this.form.name) {
                this.formErrors.push('Name is required.');
                valid = false;
            }
            if (!this.form.email) {
                this.formErrors.push('Email address is required.');
                valid = false;
            }
            if (!this.form.phone) {
                this.formErrors.push('Phone number is required.');
                valid = false;
            }
            if (!this.form.cardNo) {
                this.formErrors.push('Card number is required.');
                valid = false;
            }
            if (!this.form.expiration) {
                this.formErrors.push('Expiration date is required.');
                valid = false;
            }
            if (!this.form.cvv) {
                this.formErrors.push('CVV is required.');
                valid = false;
            }

            return valid;
        },
        processPayment() {
            this.isLoading = true;
            console.log("Form Data:", this.form);


            // Simulate a delay
            setTimeout(() => {
                this.isLoading = false;
                // Handle the actual payment process here
                this.$router.push({
                    name: 'about',
                    params: { vehicleData: this.vehicleData }
                });
            }, 3000); // Adjust the time as needed (3000ms = 3 seconds)
        },
        fetchVehicleData() {
            // Retrieve the stored VIN
            const vin = localStorage.getItem('currentVin');

            if (vin) {
                const data = localStorage.getItem(vin);

                if (data) {
                    try {
                        this.vehicleData = JSON.parse(data);

                    } catch (error) {
                        console.error('Error parsing stored vehicle data:', error);
                        this.vehicleData = null;
                    }
                } else {
                    this.vehicleData = null;
                }
            } else {
                this.vehicleData = null;
            }
        },
    },
};
</script>

<style scoped>
@media (max-width: 767px) {

    /* Mobile and tablet screens */
    .top_message {
        margin-bottom: 7rem;
        /* Adjust this value as needed */
    }

}
</style>
