<template>
  <div>
    <input v-model="vin" placeholder="Enter VIN" />
    <button @click="handleVINSearch">Search VIN</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vin: '',
    };
  },
  methods: {
    async handleVINSearch() {
      if (this.vin.trim() === '') {
        alert('Please enter a VIN');
        return;
      }

      const filePath = './API/Ligjht-truck.txt';  // Path seems correct
      console.log('Fetching file from:', filePath);

      try {
        const response = await fetch(filePath);
        const text = await response.text();
        console.log('File content:', text);

        // Extract the VIN from the link line
        const regex = new RegExp(`https://www.vindata.com/report/.*${this.vin}.*`, 'i');
        const match = regex.exec(text);
        console.log('Regex match:', match);

        if (match) {
          const jsonDataStartIndex = text.indexOf('Json : {');
          const jsonData = text.substring(jsonDataStartIndex + 7).trim(); // Extract JSON data
          console.log('Extracted JSON data:', jsonData);

          // Parse and store in local storage
          try {
            const parsedData = JSON.parse(jsonData);
            localStorage.setItem('currentVin', this.vin); // Store VIN
            localStorage.setItem(this.vin, JSON.stringify(parsedData)); // Store JSON data

            // localStorage.setItem(this.vin, JSON.stringify(parsedData));
            console.log('Data stored in localStorage:', parsedData);
          } catch (parseError) {
            console.error('Failed to parse JSON data:', parseError);
            alert('There was an issue processing the vehicle data.');
            return;
          }

          // Redirect to VehicleDetails page
          this.$router.push({ name: 'VehicleDetails', params: { vin: this.vin } });
        } else {
          alert('VIN not found in the provided file.');
        }
      } catch (error) {
        console.error('Error fetching or processing the file:', error);
      }
    }

  },
};
</script>