<template>
    <div v-if="vehicleData">
      <h1>Vehicle Details</h1>
      <pre>{{ vehicleData }}</pre>
    </div>
    <div v-else>
      <p>No data found for the provided VIN.</p>
    </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        vehicleData: null,
      };
    },
    created() {
      this.fetchVehicleData();
    },
    methods: {
      fetchVehicleData() {
        // Retrieve the stored VIN
        const vin = localStorage.getItem('currentVin');
  
        if (vin) {
          const data = localStorage.getItem(vin);
  
          if (data) {
            try {
              this.vehicleData = JSON.parse(data);
              localStorage.removeItem(vin);
              localStorage.removeItem('currentVin');

            } catch (error) {
              console.error('Error parsing stored vehicle data:', error);
              this.vehicleData = null;
            }
          } else {
            this.vehicleData = null;
          }
        } else {
          this.vehicleData = null;
        }
      },
      
    },
  };
  </script>
  